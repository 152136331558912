@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --platinum: #EAE6E5;
  --darksienna: #3D050F;
  --smokyblack: #181616;
  --almostwhite: #FCFCFC;
}

::-webkit-scrollbar {
  width: 16px;
  /* height: 16px; */
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  /* background: #290330; */
  background: hsl(12, 11%, 55%);
  /* border: 1px solid #eae6e5; */
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: hsl(12, 11%, 65%);
  /* border-color: #eae6e5;s */
}
::-webkit-scrollbar-thumb:active {
  background: hsl(12, 11%, 65%);
}
::-webkit-scrollbar-track {
  /* background: #eae6e5; */
  /* background: #fcfcfc; */
  background: transparent;
  border: 0px none #ffffff;
  /* border-radius: 6px; */
}
::-webkit-scrollbar-track:hover {
  background: hsl(12, 11%, 80%);
}
::-webkit-scrollbar-track:active {
  background: hsl(12, 11%, 80%);
}
::-webkit-scrollbar-corner {
  background: transparent;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

p, span, li {
  box-sizing: border-box;
  scroll-behavior: smooth;
  line-height: 1.5 !important;
}

.gray-to-black {
  background: linear-gradient(90deg, #4d4d4d 37.27%, #0F0E0E 60.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gray-to-black-smaller-text {
  background: linear-gradient(90deg, #6B6B6B 0.27%, #0F0E0E 11.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.almost-white {
  background-color: var(--almostwhite);
}

.almost-white-text {
  color: var(--almostwhite);
}

.bg-color {
  background-color: var(--platinum)
}

.platinum-ish-text {
  color: #EAE6E5 !important;
}

html, body, #root {
  /* height: 100%; */
  height: 100vh;
  margin: 0px;
  padding: 0px;
}

.brand-gradient {
  /* Brand Gradient */
  background: linear-gradient(90.83deg, #EB1E43 22.75%, #A11692 90%, #A11692 149.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  fill: #EB1E43 !important;
}

.brand-gradient-flipped {
  /* Brand Gradient */
  background: linear-gradient(-90.83deg, #EB1E43 0.75%, #A11692 78.91%, rgba(161, 22, 146, 0) 149.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  fill: hsl(306, 76%, 36%) !important;
}
.brand-gradient-smaller-text {
  background: linear-gradient(90.83deg, #EB1E43 22.75%, #A11692 46.91%, rgba(161, 22, 146, 0) 149.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.syne {
  font-family: 'Syne';
}

.lora {
  font-family: 'Lora', serif;
}

.varela {
  font-family: 'Varela Round', sans-serif;
}

.codebased-brand-ball-2 {
  width: 18px;
  height: 18px;

  filter: blur(1px);

  /* Brand Gradient */
  background: linear-gradient(90.83deg, #EB1E43 22.75%, #A11692 78.91%, rgba(161, 22, 146, 0) 149.99%);
}

.codebased-brand-ball {
  width: 12px;
  height: 12px;

  filter: blur(1px);

  /* Brand Gradient */
  background: linear-gradient(90.83deg, #EB1E43 22.75%, #A11692 78.91%, rgba(161, 22, 146, 0) 149.99%);
}

p, h1, span, button, a, li, label {
  color: #3D050F;
}

/* .philosophy-card {
  box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.05);
}  */

.alegreya {
  font-family: 'Alegreya', serif;
}

.alegreya-sans {
  font-family: 'Alegreya Sans', sans-serif;
}

.alegreya-sc {
  font-family: 'Alegreya SC', serif;
}

abbr {
  text-transform: uppercase;
}

/* .secondary-button {
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d) !important;
  border: 1px solid;
  border-image-slice: 1;
} */

.secondary-button {
  /* background: linear-gradient(var(--platinum), var(--platinum)) padding-box, linear-gradient(90.83deg, #EB1E43 22.75%, #A11692 90%, #A11692 149.99%) border-box; */
  background: linear-gradient(var(--platinum), var(--platinum)) padding-box, linear-gradient(90.83deg, #D31335 22.75%, #941486 78.91%, #A11692 149.99%) border-box;
  border-radius: 4px;
  border: 1px solid transparent;
}

.primary-button {
  background: linear-gradient(90.83deg, #BB112F 22.75%, #941486 78.91%, rgba(161, 22, 146, 0) 149.99%);
  border-radius: 4px;
}

/* .button-box-shadow {
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.25);
} */

.button-box-shadow:hover {
  box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.25);
}

.menu-btn-box-shadow {
  box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.25);
}

.top-btn-shadow:active {
  box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.25);
}

.introduction-btn-shadow:active {
  box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.25);
}

.link {
  text-decoration: none;
  border-bottom: 1px solid hsla(349, 85%, 13%, 0.5);
  box-shadow: inset 0 -3px 0 hsla(349, 85%, 13%, 0.5);
  transition: background 0.3s ease-out;
}

.link:hover {
  background: hsla(349, 85%, 13%, 0.25);
}

.footer-link {
  text-decoration: none;
  border-bottom: 1px solid hsla(349, 85%, 43%, 0.5);
  box-shadow: inset 0 -3px 0 hsla(349, 85%, 43%, 0.5);
  transition: background 0.3s ease-out;
}

.footer-link:hover {
  background: hsla(349, 85%, 43%, 0.25);
}