@keyframes
shrinkWidth {
    0% {
        width: 100%;
    }
    100% {
        width: 0px;
    }
}

@keyframes
fadeToPoint4 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.4;
    }
}

@keyframes scaleXandY {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1.2, 1.3);
    }
}

.brand-gradient2 {
    background: linear-gradient(90.83deg, #EB1E43 22.75%, rgba(161, 22, 146, 0.89) 149.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.spotlight-shadow-img {
    filter: drop-shadow(10px 10px 2px rgba(0, 0, 0, 0.05));
}

.vector-line-overlay {
    width: 0px;
    animation: shrinkWidth 1200ms cubic-bezier(0.55, 0.06, 0.68, 0.19);
}

.vector-line {
    opacity: 0.4;
    transform: scale(1, 1);
    animation: fadeToPoint4 1000ms linear, scaleXandY 30s linear infinite alternate;
}

.vector1 {
    animation: vector1 1500ms cubic-bezier(0.55, 0.06, 0.68, 0.19);
}

.stack-vertically {
    writing-mode: vertical-rl;
    text-orientation: upright;
}